import React from 'react'
import './Documents.scss'

const Documents = (props) => {
  return (
    <div className="documents">
      <div className="document">
        <a className="document__link" href="/files/Licence.pdf" target="_blank">
          <div className="document__img">
            <svg className="document__name" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 482.14 482.14">
              <g>
                <path d="M302.598,0H108.967C80.661,0,57.652,23.025,57.652,51.315v379.509c0,28.289,23.009,51.315,51.315,51.315h264.205
                  c28.274,0,51.315-23.026,51.315-51.315V121.449L302.598,0z M373.172,450.698H108.967c-10.97,0-19.891-8.905-19.891-19.874V51.315
                  c0-10.953,8.921-19.858,19.891-19.858l181.874-0.189v67.218c0,19.653,15.949,35.603,35.587,35.603l65.878-0.189l0.725,296.925
                  C393.031,441.793,384.142,450.698,373.172,450.698z"/>
                <path d="M297.413,246.059h-24.634v-73.058c0-7.414-6.004-13.42-13.411-13.42h-36.626c-7.392,0-13.396,6.006-13.396,13.42v73.058
                  H184.71c-4.065,0-7.723,2.458-9.299,6.225c-1.559,3.75-0.709,8.085,2.191,10.978l56.327,56.335c3.94,3.94,10.323,3.94,14.247,0
                  l56.359-56.335c2.884-2.893,3.736-7.228,2.191-10.978C305.152,248.517,301.48,246.059,297.413,246.059z"/>
              </g>
            </svg>
          </div>
          <div className="document__name">Свидетельство о регистрации</div>
        </a>
      </div>
      <div className="document">
        <a className="document__link" href="/files/FNS.pdf" target="_blank">
          <div className="document__img">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 482.14 482.14">
              <g>
                <path d="M302.598,0H108.967C80.661,0,57.652,23.025,57.652,51.315v379.509c0,28.289,23.009,51.315,51.315,51.315h264.205
                  c28.274,0,51.315-23.026,51.315-51.315V121.449L302.598,0z M373.172,450.698H108.967c-10.97,0-19.891-8.905-19.891-19.874V51.315
                  c0-10.953,8.921-19.858,19.891-19.858l181.874-0.189v67.218c0,19.653,15.949,35.603,35.587,35.603l65.878-0.189l0.725,296.925
                  C393.031,441.793,384.142,450.698,373.172,450.698z"/>
                <path d="M297.413,246.059h-24.634v-73.058c0-7.414-6.004-13.42-13.411-13.42h-36.626c-7.392,0-13.396,6.006-13.396,13.42v73.058
                  H184.71c-4.065,0-7.723,2.458-9.299,6.225c-1.559,3.75-0.709,8.085,2.191,10.978l56.327,56.335c3.94,3.94,10.323,3.94,14.247,0
                  l56.359-56.335c2.884-2.893,3.736-7.228,2.191-10.978C305.152,248.517,301.48,246.059,297.413,246.059z"/>
              </g>
            </svg>
          </div>
          <div className="document__name">Свидетельство о постановке на налоговый учет</div>
        </a>
      </div>
      <div className="document">
        <a className="document__link" href="/files/EGR.pdf" target="_blank">
          <div className="document__img">
            <svg className="document__name" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 482.14 482.14">
              <g>
                <path d="M302.598,0H108.967C80.661,0,57.652,23.025,57.652,51.315v379.509c0,28.289,23.009,51.315,51.315,51.315h264.205
                  c28.274,0,51.315-23.026,51.315-51.315V121.449L302.598,0z M373.172,450.698H108.967c-10.97,0-19.891-8.905-19.891-19.874V51.315
                  c0-10.953,8.921-19.858,19.891-19.858l181.874-0.189v67.218c0,19.653,15.949,35.603,35.587,35.603l65.878-0.189l0.725,296.925
                  C393.031,441.793,384.142,450.698,373.172,450.698z"/>
                <path d="M297.413,246.059h-24.634v-73.058c0-7.414-6.004-13.42-13.411-13.42h-36.626c-7.392,0-13.396,6.006-13.396,13.42v73.058
                  H184.71c-4.065,0-7.723,2.458-9.299,6.225c-1.559,3.75-0.709,8.085,2.191,10.978l56.327,56.335c3.94,3.94,10.323,3.94,14.247,0
                  l56.359-56.335c2.884-2.893,3.736-7.228,2.191-10.978C305.152,248.517,301.48,246.059,297.413,246.059z"/>
              </g>
            </svg>
          </div>
          <div className="document__name">Выписка ЕГР</div>
        </a>
      </div>
      <div className="document">
        <a className="document__link" href="/files/Ustav.pdf" target="_blank">
          <div className="document__img">
            <svg className="document__name" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 482.14 482.14">
              <g>
                <path d="M302.598,0H108.967C80.661,0,57.652,23.025,57.652,51.315v379.509c0,28.289,23.009,51.315,51.315,51.315h264.205
                  c28.274,0,51.315-23.026,51.315-51.315V121.449L302.598,0z M373.172,450.698H108.967c-10.97,0-19.891-8.905-19.891-19.874V51.315
                  c0-10.953,8.921-19.858,19.891-19.858l181.874-0.189v67.218c0,19.653,15.949,35.603,35.587,35.603l65.878-0.189l0.725,296.925
                  C393.031,441.793,384.142,450.698,373.172,450.698z"/>
                <path d="M297.413,246.059h-24.634v-73.058c0-7.414-6.004-13.42-13.411-13.42h-36.626c-7.392,0-13.396,6.006-13.396,13.42v73.058
                  H184.71c-4.065,0-7.723,2.458-9.299,6.225c-1.559,3.75-0.709,8.085,2.191,10.978l56.327,56.335c3.94,3.94,10.323,3.94,14.247,0
                  l56.359-56.335c2.884-2.893,3.736-7.228,2.191-10.978C305.152,248.517,301.48,246.059,297.413,246.059z"/>
              </g>
            </svg>
          </div>
          <div className="document__name">Устав</div>
        </a>
      </div>
    </div>
  )
}

export default Documents
