import React from 'react'
import './Partners.scss'

const partnersList = [
  {
    name: 'TForma',
    logo: {
      path: '/img/sponsors/tforma.jpg'
    },
    links: {
      url: "https://tforma.ru/"
    }
  },
  {
    name: 'ПАРАНГОН',
    logo: {
      path: '/img/sponsors/parangon.png',
      size: '100% auto'
    },
    links: {
      url: "https://parangonv.ru/"
    }
  },
  {
    name: 'Мясо есть',
    logo: {
      path: '/img/sponsors/myaso_est.jpg'
    },
    links: {
      url: "https://www.meatisru.com"
      // instagram: "https://www.instagram.com/meatisru/"
    }
  },
  {
    name: 'Шинный двор',
    logo: {
      path: '/img/sponsors/shindvor.jpg'
    },
    links: {
      url: "https://shindvor.com/"
    }
  },
  {
    name: 'ООО Смена',
    logo: {
      path: '/img/sponsors/smena.jpg'
    },
    links: {
      url: "https://smena68.ru/"
    }
  },
  {
    name: 'Вкус Черноземья',
    logo: {
      path: '/img/sponsors/vkus_chernozemya.png',
      size: '100% auto'
    },
    links: {
      url: "http://vkusvrn.ru/"
    }
  },
  {
    name: 'Арт-Праздник',
    logo: {
      path: '/img/sponsors/art-prazdnik.jpg',
      size: '100% auto'
    },
    links: {
      url: "http://artprazdnik-vrn.ru/"
    }
  },
  {
    name: 'Воронежская холодильная компания',
    logo: {
      path: '/img/sponsors/vhk.jpg',
      size: 'auto 100%'
    },
    links: {
      url: "https://vhk-vrn.ru/"
    }
  }
]

const Partners = () => {

  return (
    <div className="partners">
        {
          partnersList.map(item => {
            return (
              <div className="partner"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL + item.logo.path})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '50% 50%',
                  backgroundSize:  item.logo.size ? item.logo.size : 'auto 100%',
              }}>
                <div className="partner__info">
                  <div className="partner__name">
                    {item.name}
                  </div>
                  <div className="partner__links">
                    {
                      item.links.url ? (
                      <div className="partner__link partner__web">
                        <a href={item.links.url} target="_blank">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M3.51211712,15 L8.17190229,15 C8.05949197,14.0523506 8,13.0444554 8,12 C8,10.9555446 8.05949197,9.94764942 8.17190229,9 L3.51211712,9 C3.18046266,9.93833678 3,10.9480937 3,12 C3,13.0519063 3.18046266,14.0616632 3.51211712,15 L3.51211712,15 Z M3.93551965,16 C5.12590433,18.3953444 7.35207678,20.1851177 10.0280093,20.783292 C9.24889451,19.7227751 8.65216136,18.0371362 8.31375067,16 L3.93551965,16 L3.93551965,16 Z M20.4878829,15 C20.8195373,14.0616632 21,13.0519063 21,12 C21,10.9480937 20.8195373,9.93833678 20.4878829,9 L15.8280977,9 C15.940508,9.94764942 16,10.9555446 16,12 C16,13.0444554 15.940508,14.0523506 15.8280977,15 L20.4878829,15 L20.4878829,15 Z M20.0644804,16 L15.6862493,16 C15.3478386,18.0371362 14.7511055,19.7227751 13.9719907,20.783292 C16.6479232,20.1851177 18.8740957,18.3953444 20.0644804,16 L20.0644804,16 Z M9.18440269,15 L14.8155973,15 C14.9340177,14.0623882 15,13.0528256 15,12 C15,10.9471744 14.9340177,9.93761183 14.8155973,9 L9.18440269,9 C9.06598229,9.93761183 9,10.9471744 9,12 C9,13.0528256 9.06598229,14.0623882 9.18440269,15 L9.18440269,15 Z M9.3349823,16 C9.85717082,18.9678295 10.9180729,21 12,21 C13.0819271,21 14.1428292,18.9678295 14.6650177,16 L9.3349823,16 L9.3349823,16 Z M3.93551965,8 L8.31375067,8 C8.65216136,5.96286383 9.24889451,4.27722486 10.0280093,3.21670804 C7.35207678,3.81488234 5.12590433,5.60465556 3.93551965,8 L3.93551965,8 Z M20.0644804,8 C18.8740957,5.60465556 16.6479232,3.81488234 13.9719907,3.21670804 C14.7511055,4.27722486 15.3478386,5.96286383 15.6862493,8 L20.0644804,8 L20.0644804,8 Z M9.3349823,8 L14.6650177,8 C14.1428292,5.03217048 13.0819271,3 12,3 C10.9180729,3 9.85717082,5.03217048 9.3349823,8 L9.3349823,8 Z M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z"/>
                          </svg>
                        </a>
                      </div>) : ''
                    }
                    {
                      item.links.instagram ? (
                      <div className="partner__link partner__instagram">
                        <a href={item.links.instagram} target="_blank">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512">
                            <path d="M505,257c0,34.8-0.7,69.7,0.2,104.5c1.5,61.6-37.2,109.2-86.5,130.4c-19.8,8.5-40.6,13-62.1,13c-67.3,0.1-134.7,1-202-0.3   c-50.7-1-92.4-22.2-122.3-64c-15.7-22-23.2-47-23.2-74.1c0-71.7,0-143.3,0-215c0-58.5,28.5-99.4,79.1-126C110.2,14,134.1,9.1,159,9   c65.3,0,130.7-0.4,196,0.2c50.7,0.4,93,19.8,124.2,60.6c17.4,22.8,25.8,49,25.8,77.8C505,184,505,220.5,505,257z M46,257   c0,36.7,0,73.3,0,110c0,16.4,3.8,31.8,12.3,45.7c22.3,36.5,56,54.3,97.8,55c67.1,1,134.3,0.4,201.5,0.2c16.5,0,32.5-3.4,47.4-10.5   c40.6-19.4,63.3-50.3,63.1-96.7c-0.4-71-0.1-142-0.1-213c0-20.1-5.7-38.5-17.6-54.7c-23-31.1-54.8-46.4-92.8-46.8   c-67-0.8-134-0.3-201-0.2c-14.3,0-28.1,2.9-41.5,7.9c-36.8,13.7-71,48.4-69.4,99.5C46.9,188,46,222.5,46,257z"/>
                            <path d="M257.6,363c-64.5,0-116.5-51.4-116.6-115.4c-0.1-63,52.3-114.6,116.4-114.6c64.3-0.1,116.5,51.4,116.6,114.9   C374,311.3,321.9,362.9,257.6,363z M257.6,326c43.9,0,79.5-35.1,79.4-78.3c-0.1-42.8-35.7-77.8-79.4-77.8   c-43.9,0-79.7,34.9-79.7,78C178,291.1,213.7,326.1,257.6,326z"/>
                            <path d="M387.5,98c13.5,0,24.5,11.5,24.5,25.6c-0.1,14.1-11.2,25.5-24.7,25.4c-13.3-0.1-24.2-11.5-24.2-25.3   C363,109.6,374,98,387.5,98z"/>
                          </svg>
                        </a>
                      </div>) : ''
                    }
                  </div>
                </div>
              </div>
            )
          })
        }
    </div>
  )
}

export default Partners
